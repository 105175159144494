<template>
  <div>
    <Dialog
      id="listarOrdensJudiciaisModal"
      :containerStyle="{ width: '90%' }"
      :header="
        mutableDadosConsignacao.numeroContrato != null
          ? 'Ordens judiciais do contrato ' +
            mutableDadosConsignacao.numeroContrato
          : 'Ordens judiciais'
      "
      :visible="visible"
      :closeOnEscape="false"
      modal
      @update:visible="fecharModal()">
      <DataTable
        class="p-datatable-sm"
        :value="ordensJudiciais"
        responsiveLayout="scroll"
        :loading="loading">
        <template #empty> Nenhuma ordem judicial cadastrada. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <template v-if="estaNoModuloGestao()" #header>
          <div class="flex justify-content-end gap-2">
            <Button
              label="Nova Ordem Judicial"
              icon="pi pi-plus"
              @click="inserirOrdemJudicial(mutableDadosConsignacao)" />
          </div>
        </template>
        <Column
          v-for="col of columns"
          :key="col.field"
          :field="col.field"
          :header="col.header">
        </Column>
        <Column field="valorMovimento" header="Valor do Movimento">
          <template #body="{ data }">
            {{ data.valorMovimento | formatarValor }}
          </template>
        </Column>
        <Column field="dataInicio" header="Início">
          <template #body="{ data }">
            {{ data.dataInicio | formatarData }}
          </template>
        </Column>
        <Column field="dataFim" header="Fim">
          <template #body="{ data }">
            {{ data.dataFim | formatarData }}
          </template>
        </Column>
        <Column
          field="acoes"
          header="Ações"
          class="flex justify-content-center">
          <template #body="{ data }">
            <span class="flex justify-content-center">
              <Button
                v-tooltip.bottom="'Visualizar'"
                class="p-button-rounded p-button-outlined"
                style="margin-right: 4px"
                icon="pi pi-eye"
                aria-label="Visualizar Ordem Judicial"
                size="small"
                @click="modalVisualizarOrdemJudicial(data)" />
              <Button
                v-if="!data.ordemJudicialEncerrada && estaNoModuloGestao()"
                v-tooltip.bottom="'Editar'"
                class="p-button-rounded p-button-outlined"
                style="margin-right: 4px"
                icon="pi pi-pencil"
                aria-label="Editar Ordem Judicial"
                size="small"
                @click="modalEditarOrdemJudicial(data)" />
            </span>
          </template>
        </Column>
      </DataTable>
    </Dialog>
    <InserirVisualizarOrdemJudicialModal
      :visible.sync="visibleInserirVisualizarOrdemJudicialModal"
      :dadosConsignacao="mutableDadosConsignacao"
      :dadosOrdemJudicial="dadosOrdemJudicial"
      :viewFlag="flagVisualizarOrdemJudicial"
      :updateFlag="flagUpdate"
      @atualizarLista="listarOrdensJudiciais(mutableDadosConsignacao.id)" />
  </div>
</template>
<script>
import InserirVisualizarOrdemJudicialModal from '@/components/shared/modal/InserirVisualizarOrdemJudicialModal.vue'
import ConsignacaoService from '@/service/ConsignacaoService'

export default {
  components: {
    InserirVisualizarOrdemJudicialModal,
  },
  props: {
    dadosConsignacao: { type: Object, required: false, default: null },
    visible: { type: Boolean, required: true },
  },
  data() {
    return {
      loading: false,
      columns: [
        { field: 'id', header: 'ID' },
        { field: 'numeroProcesso', header: 'Número do Processo' },
        { field: 'numeroOficio', header: 'Número Ofício' },
        { field: 'juizadoNome', header: 'Juizado' },
        { field: 'juizNome', header: 'Juiz' },
      ],
      ordensJudiciais: [],
      visibleInserirVisualizarOrdemJudicialModal: false,
      flagVisualizarOrdemJudicial: true,
      mutableDadosConsignacao: {}, //resolve Unexpected mutation of "dadosConsignacao" prop (Lint)
      dadosOrdemJudicial: {},
      flagUpdate: false,
    }
  },

  watch: {
    visible: function () {
      this.mutableDadosConsignacao = this.dadosConsignacao
      this.listarOrdensJudiciais(this.mutableDadosConsignacao.id)
    },
  },
  created() {
    this.consignacaoService = new ConsignacaoService(this.$http)
  },

  methods: {
    inserirOrdemJudicial(data) {
      this.$confirm.require({
        message: 'Deseja inserir uma nova ordem judicial?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.mutableDadosConsignacao = data
          this.dadosOrdemJudicial = {}
          this.flagUpdate = false
          this.flagVisualizarOrdemJudicial = false
          this.visibleInserirVisualizarOrdemJudicialModal = true
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },

    listarOrdensJudiciais(idConsignacao) {
      this.loading = true
      this.consignacaoService
        .listOrdensJudiciaisById(idConsignacao)
        .then((result) => {
          this.ordensJudiciais = result
        })
        .catch((error) => {
          this.$toast.add({
            severity: 'error',
            summary: error.response.data.message,
            life: 5000,
          })
        })
        .finally(() => {
          this.loading = false
          this.updateFlag = false
        })
    },

    encerrarOrdemJudicial(idOrdemJudicial) {
      this.$confirm.require({
        message:
          'Tem certeza que deseja encerrar essa ordem judicial? Ao encerrar uma ordem judicial o usuário não poderá mais editá-la.',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          //console.log(idConsignacao);
          this.loading = true
          this.consignacaoService
            .encerrarOrdemJudicialById(idOrdemJudicial)
            .then(() => {
              this.listarOrdensJudiciais(this.mutableDadosConsignacao.id)
              this.$toast.add({
                severity: 'success',
                summary: 'Ordem Judicial encerrada com sucesso!',
                life: 5000,
              })
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: error.response.data.message,
                life: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },

    excluirOrdemJudicial(idOrdemJudicial) {
      this.$confirm.require({
        message: 'Tem certeza que deseja excluir essa ordem judicial?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          //console.log(idConsignacao);
          this.loading = true
          this.consignacaoService
            .deletarOrdemJudicialById(idOrdemJudicial)
            .then(() => {
              this.listarOrdensJudiciais(this.mutableDadosConsignacao.id)
              this.$toast.add({
                severity: 'success',
                summary: 'Ordem Judicial excluída com sucesso!',
                life: 5000,
              })
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: error.response.data.message,
                life: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },

    modalVisualizarOrdemJudicial(data) {
      this.dadosOrdemJudicial = data
      this.flagVisualizarOrdemJudicial = true
      this.flagUpdate = false
      this.visibleInserirVisualizarOrdemJudicialModal = true
    },

    modalEditarOrdemJudicial(data) {
      this.dadosOrdemJudicial = data
      this.flagVisualizarOrdemJudicial = false
      this.flagUpdate = true
      this.visibleInserirVisualizarOrdemJudicialModal = true
    },

    fecharModal() {
      this.$emit('update:visible', false)
      this.$emit('atualizarListaConsignacoes')
    },
  },
}
</script>
