var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"id":"alteracaoPorOrdemJudicialModal","containerStyle":{ width: '50rem' },"header":_vm.updateFlag
        ? 'Editar ordem judicial'
        : _vm.viewFlag
        ? 'Visualizar ordem judicial'
        : 'Inserir ordem judicial',"visible":_vm.visible,"closeOnEscape":false,"modal":""},on:{"update:visible":function($event){return _vm.fecharModal()}}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"col-12 md:col-6 field-checkbox"},[_c('Checkbox',{attrs:{"binary":true,"disabled":_vm.viewFlag || _vm.updateFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.encerrarOrdemJudicial),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "encerrarOrdemJudicial", $$v)},expression:"v$.dadosOrdemJudicial.$model.encerrarOrdemJudicial"}}),_c('label',{staticClass:"mb-0",attrs:{"for":"binary"}},[_vm._v("Ordem Judicial de encerramento")])],1),_c('div',{staticClass:"col-12 md:col-6"}),_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"numeroProcesso"}},[_vm._v("Número do processo: ")]),_c('InputMask',{staticClass:"w-full",class:{
              'p-invalid':
                _vm.submitted && _vm.v$.dadosOrdemJudicial.numeroProcesso.$invalid,
            },attrs:{"id":"numeroProcesso","mask":"9999999-99.9999.9.99.9999","disabled":_vm.viewFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.numeroProcesso),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "numeroProcesso", $$v)},expression:"v$.dadosOrdemJudicial.$model.numeroProcesso"}}),(_vm.submitted && _vm.v$.dadosOrdemJudicial.numeroProcesso.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Número do processo é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"numeroOficio"}},[_vm._v("Número do ofício: ")]),_c('InputText',{staticClass:"w-full",class:{
              'p-invalid':
                _vm.submitted && _vm.v$.dadosOrdemJudicial.numeroOficio.$invalid,
            },attrs:{"id":"numeroOficio","disabled":_vm.viewFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.numeroOficio),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "numeroOficio", $$v)},expression:"v$.dadosOrdemJudicial.$model.numeroOficio"}}),(_vm.submitted && _vm.v$.dadosOrdemJudicial.numeroOficio.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Número do ofício é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"juizadoNome"}},[_vm._v("Juizado: ")]),_c('InputText',{staticClass:"w-full",class:{
              'p-invalid':
                _vm.submitted && _vm.v$.dadosOrdemJudicial.juizadoNome.$invalid,
            },attrs:{"id":"juizadoNome","disabled":_vm.viewFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.juizadoNome),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "juizadoNome", $$v)},expression:"v$.dadosOrdemJudicial.$model.juizadoNome"}}),(_vm.submitted && _vm.v$.dadosOrdemJudicial.juizadoNome.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Juizado é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"juizNome"}},[_vm._v("Nome do Juiz: ")]),_c('InputText',{staticClass:"w-full",class:{
              'p-invalid':
                _vm.submitted && _vm.v$.dadosOrdemJudicial.juizNome.$invalid,
            },attrs:{"id":"juizNome","disabled":_vm.viewFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.juizNome),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "juizNome", $$v)},expression:"v$.dadosOrdemJudicial.$model.juizNome"}}),(_vm.submitted && _vm.v$.dadosOrdemJudicial.juizNome.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Nome do Juiz é obrigatório. ")]):_vm._e()],1),(!_vm.viewFlag)?_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{attrs:{"for":"valor"}},[_vm._v("Valor da Parcela")]),(_vm.dadosConsignacao)?_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_vm._v("R$")]),_c('InputNumber',{attrs:{"id":"valor","value":_vm.dadosConsignacao.valorParcela,"minFractionDigits":2,"disabled":""}})],1):_vm._e()]):_vm._e(),(!_vm.dadosOrdemJudicial.encerrarOrdemJudicial)?_c('div',{staticClass:"col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"valorMovimento"}},[_vm._v("Valor movimento: ")]),_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_vm._v("R$")]),_c('InputNumber',{staticClass:"w-full",class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.dadosOrdemJudicial.valorMovimento.$invalid,
              },attrs:{"id":"valorMovimento","mode":"decimal","locale":"pt-BR","minFractionDigits":2,"disabled":_vm.viewFlag || _vm.updateFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.valorMovimento),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "valorMovimento", $$v)},expression:"v$.dadosOrdemJudicial.$model.valorMovimento"}})],1),(_vm.submitted && _vm.v$.dadosOrdemJudicial.valorMovimento.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Valor movimento é obrigatório. ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"required",attrs:{"for":"observacao"}},[_vm._v("Observação")]),_c('Textarea',{staticClass:"w-full",class:{
              'p-invalid':
                _vm.submitted && _vm.v$.dadosOrdemJudicial.observacao.$invalid,
            },attrs:{"id":"observacao","autoResize":true,"disabled":_vm.viewFlag},model:{value:(_vm.v$.dadosOrdemJudicial.$model.observacao),callback:function ($$v) {_vm.$set(_vm.v$.dadosOrdemJudicial.$model, "observacao", $$v)},expression:"v$.dadosOrdemJudicial.$model.observacao"}}),_vm._v(" "),(_vm.submitted && _vm.v$.dadosOrdemJudicial.observacao.$invalid)?_c('span',{staticClass:"text-sm p-error"},[_vm._v(" O campo Observação é obrigatório. ")]):_vm._e()],1)]),(!_vm.viewFlag)?_c('div',{staticClass:"col-12 flex justify-content-end"},[_c('Button',{attrs:{"type":"submit","label":"Salvar","icon":"pi pi-save","loading":_vm.loading}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }