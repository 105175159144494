<template>
  <div>
    <Dialog
      id="alteracaoPorOrdemJudicialModal"
      :containerStyle="{ width: '50rem' }"
      :header="
        updateFlag
          ? 'Editar ordem judicial'
          : viewFlag
          ? 'Visualizar ordem judicial'
          : 'Inserir ordem judicial'
      "
      :visible="visible"
      :closeOnEscape="false"
      modal
      @update:visible="fecharModal()">
      <form @submit.prevent="validate()">
        <div class="flex flex-wrap">
          <div class="col-12 md:col-6 field-checkbox">
            <Checkbox
              v-model="v$.dadosOrdemJudicial.$model.encerrarOrdemJudicial"
              :binary="true"
              :disabled="viewFlag || updateFlag" />
            <label class="mb-0" for="binary"
              >Ordem Judicial de encerramento</label
            >
          </div>

          <div class="col-12 md:col-6"></div>

          <div class="col-12 md:col-6">
            <label for="numeroProcesso" class="required"
              >Número do processo:
            </label>
            <InputMask
              id="numeroProcesso"
              v-model="v$.dadosOrdemJudicial.$model.numeroProcesso"
              mask="9999999-99.9999.9.99.9999"
              class="w-full"
              :class="{
                'p-invalid':
                  submitted && v$.dadosOrdemJudicial.numeroProcesso.$invalid,
              }"
              :disabled="viewFlag" />
            <span
              v-if="submitted && v$.dadosOrdemJudicial.numeroProcesso.$invalid"
              class="text-sm p-error">
              O campo Número do processo é obrigatório.
            </span>
          </div>

          <div class="col-12 md:col-6">
            <label for="numeroOficio" class="required"
              >Número do ofício:
            </label>
            <InputText
              id="numeroOficio"
              v-model="v$.dadosOrdemJudicial.$model.numeroOficio"
              class="w-full"
              :class="{
                'p-invalid':
                  submitted && v$.dadosOrdemJudicial.numeroOficio.$invalid,
              }"
              :disabled="viewFlag" />
            <span
              v-if="submitted && v$.dadosOrdemJudicial.numeroOficio.$invalid"
              class="text-sm p-error">
              O campo Número do ofício é obrigatório.
            </span>
          </div>

          <div class="col-12 md:col-6">
            <label for="juizadoNome" class="required">Juizado: </label>
            <InputText
              id="juizadoNome"
              v-model="v$.dadosOrdemJudicial.$model.juizadoNome"
              class="w-full"
              :class="{
                'p-invalid':
                  submitted && v$.dadosOrdemJudicial.juizadoNome.$invalid,
              }"
              :disabled="viewFlag" />
            <span
              v-if="submitted && v$.dadosOrdemJudicial.juizadoNome.$invalid"
              class="text-sm p-error">
              O campo Juizado é obrigatório.
            </span>
          </div>

          <div class="col-12 md:col-6">
            <label for="juizNome" class="required">Nome do Juiz: </label>
            <InputText
              id="juizNome"
              v-model="v$.dadosOrdemJudicial.$model.juizNome"
              class="w-full"
              :class="{
                'p-invalid':
                  submitted && v$.dadosOrdemJudicial.juizNome.$invalid,
              }"
              :disabled="viewFlag" />
            <span
              v-if="submitted && v$.dadosOrdemJudicial.juizNome.$invalid"
              class="text-sm p-error">
              O campo Nome do Juiz é obrigatório.
            </span>
          </div>

          <div v-if="!viewFlag" class="col-12 md:col-6">
            <label for="valor">Valor da Parcela</label>
            <div v-if="dadosConsignacao" class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputNumber
                id="valor"
                :value="dadosConsignacao.valorParcela"
                :minFractionDigits="2"
                disabled />
            </div>
          </div>

          <div
            v-if="!dadosOrdemJudicial.encerrarOrdemJudicial"
            class="col-12 md:col-6">
            <label for="valorMovimento" class="required"
              >Valor movimento:
            </label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputNumber
                id="valorMovimento"
                v-model="v$.dadosOrdemJudicial.$model.valorMovimento"
                class="w-full"
                mode="decimal"
                locale="pt-BR"
                :minFractionDigits="2"
                :class="{
                  'p-invalid':
                    submitted && v$.dadosOrdemJudicial.valorMovimento.$invalid,
                }"
                :disabled="viewFlag || updateFlag" />
            </div>
            <span
              v-if="submitted && v$.dadosOrdemJudicial.valorMovimento.$invalid"
              class="text-sm p-error">
              O campo Valor movimento é obrigatório.
            </span>
          </div>

          <div class="col-12">
            <label for="observacao" class="required">Observação</label>
            <Textarea
              id="observacao"
              v-model="v$.dadosOrdemJudicial.$model.observacao"
              class="w-full"
              :class="{
                'p-invalid':
                  submitted && v$.dadosOrdemJudicial.observacao.$invalid,
              }"
              :autoResize="true"
              :disabled="viewFlag" />
            <span
              v-if="submitted && v$.dadosOrdemJudicial.observacao.$invalid"
              class="text-sm p-error">
              O campo Observação é obrigatório.
            </span>
          </div>
        </div>
        <div v-if="!viewFlag" class="col-12 flex justify-content-end">
          <Button
            type="submit"
            label="Salvar"
            icon="pi pi-save"
            :loading="loading" />
        </div>
      </form>
    </Dialog>
  </div>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ConsignacaoService from '@/service/ConsignacaoService'

export default {
  props: {
    dadosConsignacao: { type: Object, required: false, default: null },
    dadosOrdemJudicial: { type: Object, required: false, default: null },
    visible: { type: Boolean, required: true },
    viewFlag: { type: Boolean, required: true },
    updateFlag: { type: Boolean, required: true },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      submitted: false,
      loading: false,
    }
  },

  validations() {
    return {
      dadosOrdemJudicial: {
        valorMovimento: { required },
        numeroOficio: { required },
        numeroProcesso: { required },
        juizadoNome: { required },
        juizNome: { required },
        observacao: { required },
      },
    }
  },

  created() {
    this.consignacaoService = new ConsignacaoService(this.$http)
  },

  updated() {
    if (
      (this.updateFlag || this.viewFlag) &&
      this.v$.dadosOrdemJudicial.$model.valorMovimento === null
    )
      this.v$.dadosOrdemJudicial.$model.encerrarOrdemJudicial = true
  },

  methods: {
    adicionarEditarOrdemJudicial(dadosOrdemJudicial, consignacaoId) {
      let consignacao = { id: consignacaoId }
      dadosOrdemJudicial.consignacao = consignacao
      //console.log(dadosOrdemJudicial)

      if (this.updateFlag) {
        this.consignacaoService
          .editarOrdemJudicial(dadosOrdemJudicial)
          .then(() => {
            this.$emit('update:visible', false)
            this.$emit('atualizarLista')
            this.$toast.add({
              severity: 'success',
              summary: 'Ordem Judicial alterada com sucesso!',
              life: 5000,
            })
          })
          .catch((error) => {
            this.$toast.add({
              severity: 'error',
              summary: error.response.data.message,
              life: 5000,
            })
          })
          .finally(() => {
            this.loading = false
            this.submitted = false
          })
      } else {
        this.consignacaoService
          .adicionarOrdemJudicial(dadosOrdemJudicial)
          .then(() => {
            this.$emit('update:visible', false)
            this.$emit('atualizarLista')
            this.$toast.add({
              severity: 'success',
              summary: 'Ordem Judicial inserida com sucesso!',
              life: 5000,
            })
          })
          .catch((error) => {
            this.$toast.add({
              severity: 'error',
              summary: error.response.data.message,
              life: 5000,
            })
          })
          .finally(() => {
            this.loading = false
            this.submitted = false
          })
      }
    },

    validate() {
      this.loading = true
      this.submitted = true
      this.v$.dadosOrdemJudicial.$touch()

      if (this.dadosOrdemJudicial.encerrarOrdemJudicial)
        this.v$.dadosOrdemJudicial.$model.valorMovimento = 0

      if (this.dadosOrdemJudicial.encerrarOrdemJudicial == null)
        this.v$.dadosOrdemJudicial.$model.encerrarOrdemJudicial = false

      if (
        this.v$.dadosOrdemJudicial.numeroOficio.$invalid ||
        this.v$.dadosOrdemJudicial.numeroProcesso.$invalid ||
        this.v$.dadosOrdemJudicial.juizadoNome.$invalid ||
        this.v$.dadosOrdemJudicial.juizNome.$invalid ||
        this.v$.dadosOrdemJudicial.observacao.$invalid
      ) {
        return
      } else {
        this.adicionarEditarOrdemJudicial(
          this.dadosOrdemJudicial,
          this.dadosConsignacao.id,
        )
      }
    },

    fecharModal() {
      this.$emit('update:visible', false)
      this.submitted = false
    },
  },
}
</script>
<style lang="scss" scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
