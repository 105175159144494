var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"id":"listarOrdensJudiciaisModal","containerStyle":{ width: '90%' },"header":_vm.mutableDadosConsignacao.numeroContrato != null
        ? 'Ordens judiciais do contrato ' +
          _vm.mutableDadosConsignacao.numeroContrato
        : 'Ordens judiciais',"visible":_vm.visible,"closeOnEscape":false,"modal":""},on:{"update:visible":function($event){return _vm.fecharModal()}}},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"value":_vm.ordensJudiciais,"responsiveLayout":"scroll","loading":_vm.loading},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma ordem judicial cadastrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},(_vm.estaNoModuloGestao())?{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-content-end gap-2"},[_c('Button',{attrs:{"label":"Nova Ordem Judicial","icon":"pi pi-plus"},on:{"click":function($event){return _vm.inserirOrdemJudicial(_vm.mutableDadosConsignacao)}}})],1)]},proxy:true}:null],null,true)},[_vm._l((_vm.columns),function(col){return _c('Column',{key:col.field,attrs:{"field":col.field,"header":col.header}})}),_c('Column',{attrs:{"field":"valorMovimento","header":"Valor do Movimento"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorMovimento))+" ")]}}])}),_c('Column',{attrs:{"field":"dataInicio","header":"Início"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.dataInicio))+" ")]}}])}),_c('Column',{attrs:{"field":"dataFim","header":"Fim"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.dataFim))+" ")]}}])}),_c('Column',{staticClass:"flex justify-content-center",attrs:{"field":"acoes","header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var data = ref.data;
return [_c('span',{staticClass:"flex justify-content-center"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Visualizar'),expression:"'Visualizar'",modifiers:{"bottom":true}}],staticClass:"p-button-rounded p-button-outlined",staticStyle:{"margin-right":"4px"},attrs:{"icon":"pi pi-eye","aria-label":"Visualizar Ordem Judicial","size":"small"},on:{"click":function($event){return _vm.modalVisualizarOrdemJudicial(data)}}}),(!data.ordemJudicialEncerrada && _vm.estaNoModuloGestao())?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Editar'),expression:"'Editar'",modifiers:{"bottom":true}}],staticClass:"p-button-rounded p-button-outlined",staticStyle:{"margin-right":"4px"},attrs:{"icon":"pi pi-pencil","aria-label":"Editar Ordem Judicial","size":"small"},on:{"click":function($event){return _vm.modalEditarOrdemJudicial(data)}}}):_vm._e()],1)]}}])})],2)],1),_c('InserirVisualizarOrdemJudicialModal',{attrs:{"visible":_vm.visibleInserirVisualizarOrdemJudicialModal,"dadosConsignacao":_vm.mutableDadosConsignacao,"dadosOrdemJudicial":_vm.dadosOrdemJudicial,"viewFlag":_vm.flagVisualizarOrdemJudicial,"updateFlag":_vm.flagUpdate},on:{"update:visible":function($event){_vm.visibleInserirVisualizarOrdemJudicialModal=$event},"atualizarLista":function($event){return _vm.listarOrdensJudiciais(_vm.mutableDadosConsignacao.id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }